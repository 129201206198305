import React, { useCallback } from "react";
import PropTypes from "prop-types";

export const VisibleHeadingContext = React.createContext({
  headingsAvailable: [],
  furthestVisibleHeading: { id: "", disabled: false },
  setFurthestVisibleHeading: () => {},
});

export const VisibleHeadingContextProvider = ({ children, value }) => (
  <VisibleHeadingContext.Provider value={value}>
    {children}
  </VisibleHeadingContext.Provider>
);

VisibleHeadingContextProvider.propTypes = {
  value: PropTypes.shape({
    headingsAvailable: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    ),
    furthestVisibleHeading: PropTypes.shape({
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
    setFurthestVisibleHeading: PropTypes.func,
  }).isRequired,
  children: PropTypes.node,
};

function useVisibleHeadings() {
  const {
    headingsAvailable,
    furthestVisibleHeading,
    setFurthestVisibleHeading,
  } = React.useContext(VisibleHeadingContext);

  const resetHeading = useCallback(
    () => setFurthestVisibleHeading({ id: "", disabled: true }),
    [setFurthestVisibleHeading],
  );

  const addHeading = useCallback(
    (headingToAdd) => {
      if (headingToAdd.rect.top >= 10) {
        // 10 è una tolleranza trovata empiricamente che sembra funzionare
        setFurthestVisibleHeading({ id: headingToAdd.id, disabled: false });
      }
    },
    [setFurthestVisibleHeading],
  );

  const removeHeading = useCallback(
    (headingToRemove) => {
      if (headingToRemove.rect.bottom === window.innerHeight) {
        const index = headingsAvailable.findIndex(
          (h) => h.slug === headingToRemove.id,
        );
        if (index > 0)
          setFurthestVisibleHeading({
            id: headingsAvailable[index - 1].slug,
            disabled: false,
          });
      }
    },
    [headingsAvailable, setFurthestVisibleHeading],
  );

  return {
    furthestVisibleHeading,
    resetHeading,
    removeHeading,
    addHeading,
  };
}

export default useVisibleHeadings;
