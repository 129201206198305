import React from "react";
import PropTypes from "prop-types";

Leaf.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.any.isRequired,
  /**
   * Children generati da Slate.js da inserire nell'elemento
   */
  children: PropTypes.any.isRequired,
  /**
   * Contiene il testo e le informazioni sulla formattazione del testo
   */
  leaf: PropTypes.shape({
    text: PropTypes.string.isRequired,
    bold: PropTypes.bool,
    code: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    uppercase: PropTypes.bool,
  }),
};

function Leaf({ attributes, children, leaf }) {
  let newChildren = children;

  if (leaf.bold) {
    newChildren = <strong>{newChildren}</strong>;
  }

  if (leaf.code) {
    newChildren = <code>{newChildren}</code>;
  }

  if (leaf.italic) {
    newChildren = <em>{newChildren}</em>;
  }

  if (leaf.underline) {
    newChildren = <u>{newChildren}</u>;
  }

  if (leaf.uppercase) {
    newChildren = <span className="uppercase">{newChildren}</span>;
  }

  return <span {...attributes}>{newChildren}</span>;
}

export default Leaf;
