import React from "react";
import PropTypes from "prop-types";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export const Popover = ({ children, ...props }) => (
  <PopoverPrimitive.Root className="dropdown" {...props}>
    {children}
  </PopoverPrimitive.Root>
);

Popover.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverClose = PopoverPrimitive.Close;

export const PopoverContent = ({ children, ...props }) => {
  return (
    <PopoverPrimitive.Content className="popover__content" {...props}>
      {children}
    </PopoverPrimitive.Content>
  );
};

PopoverContent.propTypes = {
  children: PropTypes.node.isRequired,
};
