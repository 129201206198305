import React, { useCallback, useEffect } from "react";
import { useUpdateEffect } from "react-use";
import PropTypes from "prop-types";
import {
  MdMenu,
  MdChevronRight,
  MdBookmarkAdd,
  MdBookmark,
  MdDelete,
  MdAdd,
  MdClose,
} from "react-icons/md";
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import * as Accordion from "@radix-ui/react-accordion";
import { Link, useOutletContext, useLocation } from "react-router-dom";

import Icon from "~/src/components/Icon/";
import Button from "../../components/Button";
import useVisibleHeadingContext from "../../Editor/Blocks/Heading/useVisibleHeadingContext";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../../components/Tooltip";

function ConfirmationModal({
  triggerIcon,
  ariaLabel,
  text,
  onConfirm,
  tooltipText,
}) {
  return (
    <Dialog.Root>
      <Tooltip>
        <TooltipTrigger asChild>
          <Dialog.Trigger asChild>
            <Button type="ghost" size="xs" ariaLabel={ariaLabel}>
              {triggerIcon}
            </Button>
          </Dialog.Trigger>
        </TooltipTrigger>
        <TooltipContent sideOffset={2} side="top">
          {tooltipText}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
      <Dialog.Overlay className="fixed w-full h-full bg-gray-900 opacity-50" />
      <Dialog.Content
        className="sidebar-modal"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <Dialog.Description>{text}</Dialog.Description>
        <div className="sidebar-modal__actions">
          <Dialog.Close asChild>
            <Button type="ghost">Annulla</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button type="primary" onClick={onConfirm}>
              Conferma
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

ConfirmationModal.propTypes = {
  triggerIcon: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  tooltipText: PropTypes.node.isRequired,
};

function SectionRow({ children, node, permissions }) {
  const { createChapter, deleteChapter } = useOutletContext();

  return (
    <div className="sidebar-row">
      <p className="sidebar-row__title">{children}</p>
      {(permissions.canCreateChapter || permissions.canDeleteChapter) && (
        <div className="sidebar-row__actions">
          {permissions.canCreateChapter && (
            <ConfirmationModal
              text="Sei sicuro di voler creare un capitolo?"
              ariaLabel="aggiungi capitolo"
              triggerIcon={<Icon icon={MdAdd} size="20px" />}
              onConfirm={() => createChapter(node)}
              tooltipText="Crea capitolo"
            />
          )}
          {permissions.canDeleteChapter && (
            <ConfirmationModal
              text="Sei sicuro di voler cancellare il capitolo?"
              ariaLabel="cancella capitolo"
              triggerIcon={<Icon icon={MdDelete} size="20px" />}
              onConfirm={() => deleteChapter(node)}
              tooltipText="Cancella capitolo"
            />
          )}
        </div>
      )}
    </div>
  );
}

SectionRow.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.node,
  permissions: PropTypes.shape({
    canDeleteChapter: PropTypes.bool,
    canCreateChapter: PropTypes.bool,
  }),
};

function SectionHeader({
  node,
  link,
  permissions = {},
  isActive = false,
  isOpen = false,
}) {
  const anchorClass = classNames("sidebar__link", {
    "sidebar__link--active": isActive,
  });
  return (
    <Accordion.Header asChild>
      <div className="sidebar__header">
        <Accordion.Trigger asChild>
          <Button type="ghost" size="xs" classes="sidebar__chevron">
            <Icon
              icon={MdChevronRight}
              size="20px"
              aria-label={`${isOpen ? "Chiudi" : "Apri"} sezione ${node.title}`}
            />
          </Button>
        </Accordion.Trigger>
        <SectionRow node={node} permissions={permissions}>
          <Link className={anchorClass} to={link}>
            {node.title}
          </Link>
        </SectionRow>
      </div>
    </Accordion.Header>
  );
}

SectionHeader.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  permissions: PropTypes.shape({
    canDeleteChapter: PropTypes.bool,
    canCreateChapter: PropTypes.bool,
  }),
  isOpen: PropTypes.bool,
};

const EMPTY_OBJ = {};

function Section({
  node,
  isRoot = false,
  permissions = EMPTY_OBJ,
  chapterId,
  level = 0,
}) {
  const { furthestVisibleHeading } = useVisibleHeadingContext();
  const isActive = furthestVisibleHeading.id === node.slug;
  const isChildActive = node.flatChildrenSlugs?.includes(
    furthestVisibleHeading.id,
  );
  const link = isRoot ? `../${chapterId}` : `../${chapterId}#${node.slug}`;

  const [isOpen, setIsOpen] = React.useState(
    !furthestVisibleHeading.disabled && (isActive || isChildActive),
  );

  const listItemClass = classNames("", {
    "mt-2": !isRoot,
    "mt-4": isRoot,
    "pl-2": node.children.length === 0,
  });
  const anchorClass = classNames("sidebar__link", {
    "sidebar__link--active": isActive,
    "pl-6": !isRoot,
    "pl-2": isRoot,
  });

  React.useEffect(() => {
    setIsOpen((prev) => {
      if (!prev && (isActive || isChildActive)) return true;

      return prev;
    });
  }, [isChildActive, isActive]);

  const accordionRootOnValueChange = useCallback(
    (value) => setIsOpen(!!value),
    [],
  );

  if (node.children.length === 0) {
    return (
      <li className={listItemClass}>
        <SectionRow node={node} permissions={permissions}>
          <Link className={anchorClass} to={link}>
            {node.title}
          </Link>
        </SectionRow>
      </li>
    );
  }

  const id = `${node.title}-${node.id}-${level}`;

  return (
    <Accordion.Root
      asChild
      collapsible
      type="single"
      value={isOpen ? id : ""}
      onValueChange={accordionRootOnValueChange}
    >
      <Accordion.Item value={id} asChild>
        <li className={listItemClass}>
          <SectionHeader
            node={node}
            isActive={isActive}
            isOpen={isOpen}
            link={link}
            isRoot={isRoot}
            permissions={permissions}
          />
          <Accordion.Content asChild>
            <ul className="ml-4">
              {node.children.map((section) => {
                const sectionId = `${section.title}-${section.id}-${level + 1}`;
                return (
                  <Section
                    chapterId={chapterId}
                    node={section}
                    key={sectionId}
                    level={level + 1}
                  />
                );
              })}
            </ul>
          </Accordion.Content>
        </li>
      </Accordion.Item>
    </Accordion.Root>
  );
}
Section.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    slug: PropTypes.string,
    flatChildrenSlugs: PropTypes.arrayOf(PropTypes.string),
  }),
  isRoot: PropTypes.bool,
  chapterId: PropTypes.number.isRequired,
  permissions: PropTypes.shape({
    canDeleteChapter: PropTypes.bool,
    canCreateChapter: PropTypes.bool,
  }),
  level: PropTypes.number,
};

function SideBar({ bookSummary, chapterTitle, isReadOnly }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const { hash, pathname } = useLocation();
  useEffect(() => {
    const section = hash.replace("#", "");
    setIsOpen(false);
    if (section && section !== "") {
      const chaptById = document.getElementById(section);
      const heading = chaptById?.querySelector(
        "* > :where(h2, h3, h4, h5, h6)",
      );

      if (heading) {
        // Su Safari non si riesce a fare focus senza tabindex
        heading.setAttribute("tabindex", "-1");
        heading.focus();
      }
    }
  }, [hash]);

  useUpdateEffect(() => {
    setTimeout(() => {
      const heading = document.querySelector("main h2");
      if (heading) {
        // Su Safari non si riesce a fare focus senza tabindex
        heading.setAttribute("tabindex", "-1");
        heading.focus();
      }
      setIsOpen(false);
    }, 100);
  }, [pathname]);

  useEffect(() => {
    const listenerId = document.addEventListener("keyup", (evt) => {
      if (evt.code === "KeyS" && evt.shiftKey && evt.altKey) {
        setIsOpen((v) => !v);
      }
    });

    return () => {
      document.removeEventListener("keyup", listenerId);
    };
  }, []);

  const { manualBookmark, updateManualBookmark, navigateToManualBookmark } =
    useOutletContext();

  const permissions = {
    canCreateChapter: !isReadOnly,
    canDeleteChapter: !isReadOnly && bookSummary.length > 1,
  };

  return (
    <Dialog.Root
      modal={false}
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
    >
      <Dialog.Trigger className="p-2 flex btn btn-ghost rounded-btn">
        <Icon
          aria-keyshortcuts="Alt+Shift+S¯"
          label={`${isOpen ? "Chiudi" : "Apri"} sommario`}
          icon={isOpen ? MdClose : MdMenu}
          className="w-8 h-8"
        />
      </Dialog.Trigger>

      <Dialog.Content
        className="sidebar__content"
        onCloseAutoFocus={(evt) => {
          evt.preventDefault();
          return false;
        }}
      >
        <Dialog.Close asChild>
          <button className="sr-only">Chiudi sommario</button>
        </Dialog.Close>
        <Dialog.Title className="font-bold">Segnalibro</Dialog.Title>
        <div className="my-2 space-x-1.5">
          <Button
            type="primary"
            size="sm"
            onClick={() => updateManualBookmark(chapterTitle)}
          >
            {manualBookmark ? "Aggiorna" : "Salva"}
            <Icon
              className="ml-1"
              label="salva segnalibro"
              icon={MdBookmarkAdd}
              size="20px"
            />
          </Button>
          {manualBookmark && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="primary"
                  size="sm"
                  disabled={!manualBookmark}
                  onClick={navigateToManualBookmark}
                  ariaLabel="vai al segnalibro"
                >
                  Vai a
                  <Icon
                    className="ml-1"
                    label="vai al segnalibro"
                    icon={MdBookmark}
                    size="20px"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={2} side="right">
                {`${manualBookmark.percentage}% di "${manualBookmark.chapterTitle}"`}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip>
          )}
        </div>

        <Dialog.Title className="font-bold">Sommario</Dialog.Title>
        <ul>
          {bookSummary.map((section) => (
            <Section
              isRoot
              chapterId={section.id}
              key={section.id}
              node={section}
              permissions={permissions}
            />
          ))}
        </ul>
      </Dialog.Content>
    </Dialog.Root>
  );
}

SideBar.propTypes = {
  bookSummary: PropTypes.arrayOf(Section.propTypes.node),
  chapterTitle: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
};

export default SideBar;
