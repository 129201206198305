import jwtDecode from "jwt-decode";

import { loginWithSsoToken } from "./MylimAuthenticationService";

const REQUIRED_TOKEN_FIELDS = [
  "exp",
  "user_id",
  "username",
  "email",
  "loescher_roles",
];

// Funzione per leggere/scrivere il localStorage

/**
 * Legge dal local storage
 * @param {string} key
 * @returns {string|null}
 */
function localStorageGet(key) {
  return window.localStorage.getItem(key);
}

/**
 * Scrive nel localStorage
 * @param {string} key
 * @param {string} val
 * @returns {void}
 */
function localStorageSet(key, val) {
  return window.localStorage.setItem(key, val);
}

/**
 * Cancella dal localStorage
 * @param {string} key
 * @returns {void}
 */
function localStorageDelete(key) {
  return window.localStorage.removeItem(key);
}

/**
 * Legge il token JWT da storage
 *
 * @returns {string|null} il token JWT o null
 */
export function readToken() {
  return localStorageGet("token");
}

// Funzioni e tipi per manipolare il token

/**
 * @typedef TokenData
 * @type {object}
 * @property {number} user_id - l'ID dell'utente.
 * @property {string} username - l'username.
 * @property {string} email - la mail dell'utente.
 * @property {number} exp - la data di scadenza del token (epoch).
 * @property {Array.<string>} loescher_roles - l'array di permessi dell'utente.
 */
/**
 * @typedef Token
 * @type {object}
 * @property {string} token - the JWT token.
 * @property {TokenData} data - your name.
 */

/**
 * Parsa il payload del JWT e ne verifica il formato
 * @param {string | null} token il token JWT
 * @returns {Token|null} i dati parsati o null in caso di problemi
 */
export function parseTokenData(token) {
  if (!token) {
    return null;
  }

  let data;
  try {
    data = jwtDecode(token);
  } catch (err) {
    if (err.name === "InvalidTokenError") {
      return null;
    }
    throw err;
  }

  // Controlliamo validità dei dati del token
  const isTokenValid = REQUIRED_TOKEN_FIELDS.map((field) => data[field]).every(
    (field) => !!field,
  );

  if (!isTokenValid) {
    return null;
  }
  return { token, data };
}

/**
 * Controlla se il token JWT è scaduto
 * @param {Token} token - il token JWT.
 * @returns {boolean} true se il token è scaduto falso altrimenti.
 */
export function isTokenExpired(token) {
  return (token?.data?.exp || 0) * 1000 <= Date.now();
}

export function saveToken(token) {
  localStorageSet("token", token);
}

export function deleteToken() {
  localStorageDelete("token");
}

export const LOESCHER_SSO_COOKIE_NAME = "loescher_sso";
const LOESCHER_SSO_COOKIE_DOMAIN =
  process.env["NODE_ENV"] !== "development" &&
  location.hostname.endsWith("loescher.it")
    ? "loescher.it"
    : undefined;

/**
 * @param {string} token
 * @param {number|undefined} maxAgeInSeconds
 * @return {void}
 */
export function saveLoescherSsoToken(token, maxAgeInSeconds) {
  let cookie = `${LOESCHER_SSO_COOKIE_NAME}=${token}; path=/`;
  if (LOESCHER_SSO_COOKIE_DOMAIN !== undefined) {
    cookie += `; domain=${LOESCHER_SSO_COOKIE_DOMAIN}`;
  } else {
    cookie += `; SameSite=Lax`;
  }

  if (maxAgeInSeconds !== undefined) {
    cookie += `; max-age=${maxAgeInSeconds}`;
  }

  document.cookie = cookie;
}

export function deleteLoescherSsoToken() {
  let cookie = `${LOESCHER_SSO_COOKIE_NAME}=; path=/; max-age=-1`;
  if (LOESCHER_SSO_COOKIE_DOMAIN !== undefined) {
    cookie += `; domain=${LOESCHER_SSO_COOKIE_DOMAIN};`;
  }
  document.cookie = cookie;
}

/**
 * @returns {string | null}
 */
export function readLoescherSsoToken() {
  const cookieNameWithEqual = `${LOESCHER_SSO_COOKIE_NAME}=`;
  const cookieRow = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieNameWithEqual));
  if (cookieRow === undefined) {
    return null;
  }
  return cookieRow.split("=")[1] ?? null;
}

/**
 * Procedura per rinnovare il token di autenticazione con il token Loescher.
 * Se il token è rinnovato viene salvato il local storage. Se è impossibile
 * salvare il token => ritorna false.
 *
 * @returns {Promise<boolean>} true se il token è stato rinnovato, false altrimenti.
 */
export async function refreshToken() {
  const ssoToken = readLoescherSsoToken();
  if (ssoToken === null) {
    return false;
  }

  try {
    const newToken = await loginWithSsoToken(ssoToken);
    saveToken(newToken);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Ottine un token utilizzabile.
 * Se il rinnovo è impossibile => ritorna null
 *
 * @param {Token} token - il token da controllare/rinnovare.
 * @returns {Promise<Token|null>} a valid token or null.
 */
export const getFreshToken = async (token) => {
  if (!token) {
    return null;
  }
  if (!isTokenExpired(token)) {
    return token;
  }

  const tokenRefreshed = await refreshToken();
  return tokenRefreshed ? parseTokenData(readToken()) : null;
};
