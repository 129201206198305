import React from "react";
import PropTypes from "prop-types";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const Root = ({ children, ...props }) => (
  <TooltipPrimitive.Root className="tooltip" {...props}>
    {children}
  </TooltipPrimitive.Root>
);

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = ({ children, ...props }) => (
  <TooltipPrimitive.Content className="tooltip__content" {...props}>
    {children}
  </TooltipPrimitive.Content>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

const Arrow = (props) => {
  const newProps = { ...props };
  delete newProps.children;
  return <TooltipPrimitive.Arrow className="tooltip__arrow" {...newProps} />;
};

Arrow.propTypes = {
  children: PropTypes.node,
};

export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;
export const TooltipArrow = Arrow;
