import React from "react";
import PropTypes from "prop-types";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";

const Icon = ({ icon, label = null, ...props }) => {
  const IconGlyph = icon;

  return label ? (
    <AccessibleIcon.Root label={label}>
      <IconGlyph {...props} />
    </AccessibleIcon.Root>
  ) : (
    <IconGlyph {...props} />
  );
};

Icon.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default Icon;
