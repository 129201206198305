import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import {
  readToken,
  saveToken,
  deleteToken,
  parseTokenData,
  getFreshToken,
  saveLoescherSsoToken,
  deleteLoescherSsoToken,
  readLoescherSsoToken,
} from "./MylimAuthentication";
import {
  login as loginCall,
  loginWithSsoToken,
} from "./MylimAuthenticationService";
import { Api } from "../Api";
import Spinner from "../components/Spinner";

export const AuthContext = React.createContext(null);

const SSO_TOKEN_MAX_AGE_SECONDS = 92 * 24 * 3600; // 92 days ~= 3 months.

/**
 * @typedef UserInfo
 * @type {object}
 * @property {string} email
 * @property {string} role
 * @property {boolean} canEdit
 */
const defaultUserInfo = {
  email: "",
  role: "Studente",
  canEdit: false,
};

/**
 * Fornisce il Provider del context delle info dell'utente e il wrapper per
 * le chiamate api che gestisce il refresh e la gestione del token JWT.
 */
function AuthProvider({ children }) {
  const [initTokenDone, setInitTokenDone] = React.useState(false);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const navigate = useNavigate();

  const initToken = useCallback(async () => {
    // Leggiamo il token
    const tokenB = readToken();
    let token = parseTokenData(tokenB);
    token = await getFreshToken(token);
    if (!token) {
      token = await tryLoginWithSsoToken();
      if (token === null) {
        // Dobbiamo loggarci
        setInitTokenDone(true);
        return;
      }
    }
    // Autenticati !!!

    setUserInfo({
      email: token.data.email,
      role: token.data.loescher_roles[0] || "",
      canEdit: token.data.loescher_roles.includes("Redattore"),
    });

    // Refresh dell'interceptor axios con il token
    Api.interceptors.request.use(async (config) => {
      const jwt = await getFreshToken(token);
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `JWT ${jwt.token}`;

      if (token.token !== jwt.token) {
        await initToken();
      }
      return config;
    });
    setInitTokenDone(true);
  }, []);

  // All'apertura dell'app
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    initToken().catch(console.error);
  }, [initToken]);

  const login = useCallback(
    async (username, password, rememberMe) =>
      loginImpl(setInitTokenDone, initToken, username, password, rememberMe),
    [initToken],
  );

  const logout = () => {
    deleteToken();
    deleteLoescherSsoToken();
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ userInfo, login, logout }}>
      {initTokenDone ? children : <Spinner />}
    </AuthContext.Provider>
  );
}

/**
 * @param {(value: React.SetStateAction<boolean>) => void} setInitTokenDone
 * @param {() => Promise<void>} initToken
 * @param {string} username
 * @param {string} password
 * @params {boolean|undefined} rememberMe
 * @returns {Promise<void>}
 */
export async function loginImpl(
  setInitTokenDone,
  initToken,
  username,
  password,
  rememberMe,
) {
  /**
   * @type Awaited<ReturnType<typeof loginCall>>
   */
  let loginReturn;
  try {
    loginReturn = await loginCall(username, password);
  } catch (err) {
    if (err.response?.status === 400) {
      throw Error("Inserire utente e password prima di procedere con il login");
    } else if (err.response?.status === 401) {
      throw Error("Impossibile accedere con le credenziali specificate.");
    }
    throw Error("Si è verificato un errore, riprovare più tardi.");
  }
  if (loginReturn === undefined) {
    throw Error("Si è verificato un errore, riprovare più tardi.");
  }

  const { token, token_loescher } = loginReturn;

  saveToken(token);
  saveLoescherSsoToken(
    token_loescher,
    rememberMe ? SSO_TOKEN_MAX_AGE_SECONDS : undefined,
  );
  setInitTokenDone(false);
  await initToken();
}

/**
 * @returns {Promise<import("./MylimAuthentication").Token | null>}
 */
async function tryLoginWithSsoToken() {
  const ssoToken = readLoescherSsoToken();
  if (ssoToken === null) {
    return null;
  }

  /**
   * @type {Awaited<ReturnType<loginWithSsoToken>>}
   */
  let token;
  try {
    token = await loginWithSsoToken(ssoToken);
    if (!token) {
      deleteLoescherSsoToken();
      return null;
    }
  } catch {
    deleteLoescherSsoToken();
    return null;
  }

  saveToken(token);

  return parseTokenData(token);
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthProvider;
