import React from "react";
import { useReadOnly, ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import * as Dialog from "@radix-ui/react-dialog";
import { MdClose, MdFileUpload, MdFullscreen } from "react-icons/md";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";
import Icon from "../../../components/Icon";
import Button, { ButtonAction } from "../../../components/Button";
import useModifiers from "../../useModifiersContext";
import { ButtonWrapHighlightBox } from "../../BlockConverter";
import { apiUploadImage } from "../../../Api";

/**
 * Componente che renderizza un blocco immagine con descrizione
 * @param {*} param vedi PropTypes
 * @returns Un blocco immagine con descrizione
 */
function ImageBlock({ attributes, element, children = [] }) {
  const inputRef = React.useRef(null);
  const [isUploadingImage, setIsUploadingImage] = React.useState(false);
  const editor = useSlateStatic();
  const isReadOnly = useReadOnly();
  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const path = ReactEditor.findPath(editor, element);
  const converterBtns = [
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];
  if (path.length > 1) converterBtns.pop();

  useModifiers({ converterBtns, element });

  const uploadImage = (uploadEvent) => {
    if (uploadEvent.target.files && uploadEvent.target.files[0]) {
      const FR = new FileReader();

      FR.addEventListener("loadend", async (e) => {
        setIsUploadingImage(true);
        const file = uploadEvent.target.files[0];
        const formData = new FormData();
        formData.append("file", uploadEvent.target.files[0]);

        let imageUrl = null;
        try {
          const res = await toast.promise(apiUploadImage(file.name, formData), {
            loading: "Caricamento immagine...",
            success: "Immagine caricata",
            error: "Errore nel caricamento dell'immagine",
          });
          imageUrl = res.url;
        } catch (_) {
          inputRef.current.value = "";
          return;
        } finally {
          setIsUploadingImage(false);
        }

        const image = new Image(); // get aspect ratio of the new image
        image.src = e.target.result;

        image.onload = function () {
          Transforms.setNodes(
            editor,
            // eslint-disable-next-line react/no-this-in-sfc
            { url: imageUrl, aspectRatio: `${this.width}/${this.height}` },
            { at: path },
          );
        };
      });

      FR.readAsDataURL(uploadEvent.target.files[0]);
    }
  };

  const onUpload = () => inputRef?.current.click();

  if (isReadOnly) {
    return (
      <div {...attributes}>
        <Dialog.Root>
          <Dialog.Trigger
            asChild
            aria-label="ingrandisci immagine"
            style={{ aspectRatio: element.aspectRatio }}
          >
            <figure className="relative">
              <img
                className="w-full hover:cursor-pointer"
                src={element.url}
                alt={element.altText}
              />
              <figcaption>{children}</figcaption>
              <Button
                classes="absolute right-4 top-4"
                square
                outline
                type="primary"
                size="sm"
                label="Visualizza immagine a schermo intero"
              >
                <Icon icon={MdFullscreen} size="30px" />
              </Button>
            </figure>
          </Dialog.Trigger>
          <Dialog.Overlay className="fixed w-full h-full bg-gray-900 opacity-70" />
          <Dialog.Content className="fixed w-11/12 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 flex justify-center">
            <Dialog.Close className="absolute right-0 top-0 btn btn-ghost btn-square btn-md text-white">
              <Icon icon={MdClose} label="chiudi" size="30px" />
            </Dialog.Close>
            <img src={element.url} alt={element.altText} />
          </Dialog.Content>
        </Dialog.Root>
      </div>
    );
  }

  const onAltTextChange = (e) => {
    Transforms.setNodes(editor, { altText: e.target.value }, { at: path });
  };

  return (
    <div {...attributes}>
      <figure>
        <div
          className="image"
          contentEditable={false}
          style={{ aspectRatio: element.aspectRatio }}
        >
          <span className="image__bg"></span>
          <div className="image__upload">
            <input
              accept="image/*"
              aria-hidden="true"
              data-testid="file-input"
              ref={inputRef}
              className="hidden"
              type="file"
              onChange={uploadImage}
            />
            {!isUploadingImage && (
              <ButtonAction
                ariaLabel="Carica nuova immagine"
                onClick={onUpload}
                Icon={MdFileUpload}
              />
            )}
          </div>
          <img className="w-full" src={element.url} alt={element.altText} />
        </div>
        <div contentEditable={false}>
          <label className="text-xs font-bold">
            Testo alternativo
            <textarea
              defaultValue={element.altText}
              onChange={onAltTextChange}
              className="textarea textarea-primary w-full"
              placeholder="Testo alternativo"
            ></textarea>
          </label>
        </div>
        <figcaption>{children}</figcaption>
      </figure>
    </div>
  );
}

ImageBlock.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  element: PropTypes.shape({
    url: PropTypes.string.isRequired,
    aspectRatio: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.object.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

export default ImageBlock;
