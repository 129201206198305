import React from "react";
import PropTypes from "prop-types";
import { useSlateStatic } from "slate-react";

import { useToolbar, DEFAULT_TOOLBAR_BUTTONS } from "../../Toolbar";
import {
  ButtonConverterToTitle,
  ButtonConverterToBulletedList,
  ButtonConverterToOrderedList,
  ButtonWrapHighlightBox,
  ButtonConverterToQuote,
  ButtonConverterToAlphabeticList,
  ButtonConverterToExample,
} from "~/src/Editor/BlockConverter";

import useModifiers from "../../useModifiersContext";

function Paragraph({ attributes, children, element }) {
  const editor = useSlateStatic();

  useToolbar(DEFAULT_TOOLBAR_BUTTONS);

  const converterBtns = [
    <ButtonConverterToTitle
      key="changeToTitle"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToBulletedList
      key="changeToList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToOrderedList
      key="changeToOrderedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToAlphabeticList
      key="changeToAlphabeticalList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToQuote
      key="changeToQuote"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToExample
      key="changeToExample"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ converterBtns, element });

  return <p {...attributes}>{children}</p>;
}

Paragraph.propTypes = {
  /**
   * Attributi generati da Slate.js da assegnare all'elemento
   */
  attributes: PropTypes.object,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
  /**
   * Proprietà generata da Slate.js che contiene informazioni sull'elemento
   */
  element: PropTypes.shape({}),
};

export default Paragraph;
