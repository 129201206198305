import React, { useCallback, useMemo } from "react";
import { Editor, Node, Path, Transforms } from "slate";
import classNames from "classnames";
import { useReadOnly, useSlate, ReactEditor } from "slate-react";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import PropTypes from "prop-types";

import {
  ELEMENT_TYPES,
  BLOCK_WITH_CHILDREN,
  MAP_AVAILABLE_BLOCKS,
  LIST_BLOCKS,
  BLOCK_WITHOUT_POSITION_TOOLS,
} from "./constants";
import BlockCreationTools from "./BlockCreationTools";
import BlockModifiersTool from "./BlockModifiersTool";
import DeleteBlock from "./DeleteBlock";
import { ModifiersContext } from "./useModifiersContext";
import { BlockContext } from "./useBlockContext";
import BlockTools from "./BlockTools";
import { ButtonAction } from "../components/Button";
import { arraysEqual } from "./utils";

function Block({ children, element }) {
  const [modifiersButtons, setModifiersButtons] = React.useState([]);
  const [converterButtons, setConverterButtons] = React.useState([]);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const editor = useSlate();
  const isReadOnly = useReadOnly();
  const [elementPath, setElementPath] = React.useState(
    ReactEditor.findPath(editor, element),
  );
  const [blockToolsVisible, setBlockToolsVisible] = React.useState(false);

  const isEmpty = React.useMemo(
    () => Editor.isEmpty(editor, element),
    [editor, element],
  );
  const hasChildren = React.useMemo(
    () => BLOCK_WITH_CHILDREN.includes(element.type),
    [element.type],
  );
  const availableBlocks = MAP_AVAILABLE_BLOCKS[element.type];

  React.useEffect(() => {
    if (!blockToolsVisible) return;

    const newPath = ReactEditor.findPath(editor, element);
    setElementPath((oldPath) =>
      arraysEqual(newPath, oldPath) ? oldPath : newPath,
    );
  }, [editor.children, blockToolsVisible, editor, element]);

  const isFirstElement = elementPath.length === 1 && elementPath[0] === 0;
  const isLastElement =
    elementPath.length === 1 && elementPath[0] === editor.children.length - 1;
  const hasPositionTools = !BLOCK_WITHOUT_POSITION_TOOLS.includes(element.type);

  const setButtons = useCallback(
    ({ converterBtns = [], modifierBtns = [] }) => {
      setConverterButtons(converterBtns);
      setModifiersButtons(modifierBtns);
    },
    [],
  );

  const onMoveUp = React.useCallback(
    (e) => {
      e.preventDefault();

      let newPath = null;

      if (Path.hasPrevious(elementPath)) {
        const previousPath = Path.previous(elementPath);
        const previousNode = Node.get(editor, previousPath);

        if (element.type === ELEMENT_TYPES.highlightBox) {
          // Se è un highlight box inverte di posizione i due elementi in quanto non si può innestare
          newPath = Path.previous(elementPath);
        } else if (
          LIST_BLOCKS.includes(element.type) &&
          LIST_BLOCKS.includes(previousNode.type)
        ) {
          // Se sono due liste si invertono in quanto non si possono innestare
          newPath = Path.previous(elementPath);
        } else if (
          previousNode.type === ELEMENT_TYPES.quote ||
          previousNode.type === ELEMENT_TYPES.example
        ) {
          // Se l'elemento precedente è un quote/esempio si inverte
          newPath = Path.previous(elementPath);
        } else if (
          (element.type === ELEMENT_TYPES.quote ||
            element.type === ELEMENT_TYPES.example) &&
          LIST_BLOCKS.includes(previousNode.type)
        ) {
          // Quote e esempio non possono entrare in una lista
          newPath = Path.previous(elementPath);
        } else if (BLOCK_WITH_CHILDREN.includes(previousNode.type)) {
          // Se il nodo procedente ha children mettiamo l'elemento come ultimo di questi children
          const lastNode = Node.last(previousNode, [])[1][0];
          newPath = [...previousPath, lastNode + 1];

          // Trasforma il nodo in un list item se l'elemento sta entrando nella lista
          if (LIST_BLOCKS.includes(previousNode.type)) {
            Transforms.setNodes(
              editor,
              { type: ELEMENT_TYPES.listItem },
              {
                at: elementPath,
              },
            );
          }
        } else {
          newPath = Path.previous(elementPath);
        }
      } else {
        // Se non ha previous vuol dire che è il primo children di un blocco con children, va quindi
        // a occupare la posizione del blocco con i children
        newPath = Path.parent(elementPath);

        // Un quoteParagraph sta uscendo dalla quote
        if (element.type === ELEMENT_TYPES.quoteParagraph) {
          newPath = Path.parent(Path.parent(elementPath));

          Transforms.setNodes(
            editor,
            { type: ELEMENT_TYPES.paragraph },
            { at: elementPath },
          );
        }

        // Un lista item sta uscendo dalla lista
        if (element.type === ELEMENT_TYPES.listItem) {
          Transforms.setNodes(
            editor,
            { type: ELEMENT_TYPES.paragraph },
            {
              at: elementPath,
            },
          );
        }
      }

      Transforms.moveNodes(editor, {
        at: elementPath,
        to: newPath,
      });
    },
    [elementPath, editor, element.type],
  );

  const onMoveDown = React.useCallback(
    (e) => {
      e.preventDefault();

      let newPath = null;
      const nextPath = Path.next(elementPath);

      if (Node.has(editor, nextPath)) {
        const nextElement = Node.get(editor, nextPath);

        if (element.type === ELEMENT_TYPES.highlightBox) {
          // Se è un highlight box si inverte in quanto non si può innestare
          newPath = nextPath;
        } else if (
          LIST_BLOCKS.includes(element.type) &&
          LIST_BLOCKS.includes(nextElement.type)
        ) {
          // Se sono due liste si invertono in quanto non si possono innestare
          newPath = nextPath;
        } else if (
          nextElement.type === ELEMENT_TYPES.quote ||
          nextElement.type === ELEMENT_TYPES.example
        ) {
          // Se l'elemento successivo e' una quote/esempio si inverte
          newPath = nextPath;
        } else if (
          (element.type === ELEMENT_TYPES.quote ||
            element.type === ELEMENT_TYPES.example) &&
          LIST_BLOCKS.includes(nextElement.type)
        ) {
          // Quote e example non possono entrare in una lista
          newPath = nextPath;
        } else if (BLOCK_WITH_CHILDREN.includes(nextElement.type)) {
          // Se il prossimo elemento ha children viene messo come primo children
          newPath = [...nextPath, 0];

          // Trasforma il nodo in un list item se l'elemento sta entrando nella lista
          if (LIST_BLOCKS.includes(nextElement.type)) {
            Transforms.setNodes(
              editor,
              { type: ELEMENT_TYPES.listItem },
              {
                at: elementPath,
              },
            );
          }
        } else {
          newPath = nextPath;
        }
      } else {
        // Se non ha un sibling vuol dire che è l'ultimo elemento di un blocco con children e si metterà
        // dopo il blocco con children
        let parent = Path.parent(elementPath);

        if (element.type === ELEMENT_TYPES.quoteParagraph) {
          parent = Path.parent(Path.parent(elementPath));
        }

        newPath = Path.next(parent);

        // Un list item sta uscendo dalla lista
        if (element.type === ELEMENT_TYPES.listItem) {
          Transforms.setNodes(
            editor,
            { type: ELEMENT_TYPES.paragraph },
            {
              at: elementPath,
            },
          );
        }

        // Un quoteParagraph sta uscendo dalla quote
        if (element.type === ELEMENT_TYPES.quoteParagraph) {
          Transforms.setNodes(
            editor,
            { type: ELEMENT_TYPES.paragraph },
            {
              at: elementPath,
            },
          );
        }
      }

      Transforms.moveNodes(editor, {
        at: elementPath,
        to: newPath,
      });
    },
    [elementPath, editor, element.type],
  );

  const blockToolsShow = useCallback(() => setBlockToolsVisible(true), []);
  const blockToolsHide = useCallback(() => setBlockToolsVisible(false), []);

  const modifiersContextData = useMemo(
    () => ({
      converterBtns: converterButtons,
      modifierBtns: modifiersButtons,
      setButtons,
    }),
    [setButtons, converterButtons, modifiersButtons],
  );

  const blockContextData = useMemo(
    () => ({
      setIsHighlighted,
    }),
    [setIsHighlighted],
  );

  if (
    [ELEMENT_TYPES.glossario, ELEMENT_TYPES.link].includes(element.type) ||
    isReadOnly
  )
    return children;

  return (
    <div
      className={classNames("block", {
        "block--highlighted": blockToolsVisible || isHighlighted,
        "block--empty": isEmpty,
        "block--parent": hasChildren,
      })}
      data-testid="block-outline"
      onMouseEnter={blockToolsShow}
      onMouseLeave={blockToolsHide}
    >
      <BlockContext.Provider value={blockContextData}>
        {blockToolsVisible && hasPositionTools && (
          <BlockTools type="position" hasNestedBlocks={hasChildren}>
            {!isFirstElement && (
              <ButtonAction
                ariaLabel="Sposta in alto"
                onClick={onMoveUp}
                Icon={MdArrowUpward}
              />
            )}
            {!isLastElement && (
              <ButtonAction
                ariaLabel="Sposta in basso"
                onClick={onMoveDown}
                Icon={MdArrowDownward}
              />
            )}
            <DeleteBlock type={element.type} path={elementPath} />
          </BlockTools>
        )}
      </BlockContext.Provider>

      <ModifiersContext.Provider value={modifiersContextData}>
        <div className="block__wrapper">{children}</div>
      </ModifiersContext.Provider>

      {blockToolsVisible && availableBlocks?.length > 0 && (
        <BlockTools type="creation">
          <BlockCreationTools
            path={elementPath}
            availableBlocks={availableBlocks}
          />
        </BlockTools>
      )}

      {blockToolsVisible &&
        (modifiersButtons.length > 0 || converterButtons.length > 0) && (
          <BlockTools type="modifiers">
            <BlockModifiersTool
              modifiers={modifiersButtons}
              converters={converterButtons}
            />
          </BlockTools>
        )}
    </div>
  );
}

Block.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
  /**
   * Slate element data
   */
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default Block;
