import React, { useCallback } from "react";
import { useParams, Outlet, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import Spinner from "../components/Spinner";
import Header from "../layout/Header";
import {
  apiDeleteChapter,
  getBookSummary,
  getChapter,
  postChapter,
} from "../Api";
import {
  getAutomaticBookmarkName,
  loadBookmark,
  useAutomaticBookmark,
  useManualBookmark,
} from "../utils/bookmark";
import { withAuthetication } from "../Auth";

function Book() {
  const { isbn, chapter_id: chapterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [automaticBookmark, setAutomaticBookmark] = React.useState(null);
  const { manualBookmark, updateManualBookmark, navigateToManualBookmark } =
    useManualBookmark(isbn, chapterId);
  const [bookSummary, setBookSummary] = React.useState({
    book: {},
    chapters: [],
  });
  useAutomaticBookmark(isbn, chapterId);

  const redirectDataRef = React.useRef({
    chapterId,
    locationHash: location.hash,
    navigate,
  });

  React.useEffect(() => {
    redirectDataRef.current = {
      chapterId,
      locationHash: location.hash,
      navigate,
    };
  }, [chapterId, location.hash, navigate]);

  const redirectToChapterIfNecessary = useCallback(
    (sections = []) => {
      const bookmark = loadBookmark(getAutomaticBookmarkName(isbn));
      setAutomaticBookmark(bookmark);

      const { chapterId, locationHash, navigate } = redirectDataRef.current;

      // Se presente hash in url non aggiorna il location.state
      if (locationHash) return;

      let chapterToRedirect = chapterId;
      let scrollY = 0;

      if (!chapterId && bookmark) {
        chapterToRedirect = bookmark.chapterId;
        scrollY = bookmark.scrollY;
      } else if (!chapterId) {
        chapterToRedirect = sections[0].id.toString();
      } else if (chapterId) {
        const chapterExists = sections.find(
          (s) => s.id.toString() === chapterId,
        );
        if (!chapterExists) chapterToRedirect = sections[0].id.toString();
        if (chapterExists && chapterId === bookmark?.chapterId)
          scrollY = bookmark.scrollY;
      }

      navigate(chapterToRedirect, { replace: true, state: { scrollY } });
    },
    [isbn],
  );

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const summaryData = await getBookSummary(isbn);
        setBookSummary(summaryData);
        redirectToChapterIfNecessary(summaryData.chapters);
      } catch (_) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })().catch();
  }, [isbn, redirectToChapterIfNecessary]);

  const createChapter = async (node) => {
    setLoading(true);
    const chapterIndex = bookSummary.chapters.findIndex(
      (c) => c.id === node.id,
    );
    const nextChapterIndex =
      bookSummary.chapters.length - 1 >= chapterIndex + 1
        ? chapterIndex + 1
        : null;

    let chapter = null;
    let nextChapter = null;

    try {
      // Recupera le informazione dell'ordine per il capitolo attuale e successivo
      chapter = await getChapter(node.id);
      if (nextChapterIndex) {
        nextChapter = await getChapter(
          bookSummary.chapters[nextChapterIndex].id,
        );
      }

      let order = null;
      if (nextChapter) {
        order = chapter.order + (nextChapter.order - chapter.order) / 2;
      } else {
        order = chapter.order + 1;
      }
      const bookId = bookSummary.book.id;
      const result = await postChapter(bookId, order);

      setBookSummary(({ book, chapters }) => {
        const newChapters = [
          ...chapters.slice(0, chapterIndex + 1),
          result.toc,
          ...chapters.slice(chapterIndex + 1, chapters.length),
        ];
        return {
          book,
          chapters: newChapters,
        };
      });

      navigate(result.id.toString());
    } catch (_) {
      toast.error("Errore nella creazione");
    } finally {
      setLoading(false);
    }
  };

  const deleteChapter = async (node) => {
    const chapterIndex = bookSummary.chapters.findIndex(
      (c) => c.id === node.id,
    );

    try {
      await toast.promise(
        apiDeleteChapter(node.id),
        {
          loading: "Cancellazione...",
          success: "Capitolo cancellato",
          error: "Capitolo non cancellato",
        },
        {
          style: { minWidth: "230px" },
        },
      );

      const newChapters = [
        ...bookSummary.chapters.slice(0, chapterIndex),
        ...bookSummary.chapters.slice(
          chapterIndex + 1,
          bookSummary.chapters.length + 1,
        ),
      ];

      setBookSummary(({ book }) => ({
        book,
        chapters: newChapters,
      }));

      if (chapterId === node.id.toString()) {
        if (newChapters[chapterIndex]) {
          navigate(newChapters[chapterIndex].id.toString());
        } else {
          navigate(newChapters[newChapters.length - 1].id.toString());
        }
      }
    } catch (_) {
      // gestito nel toast
    }
  };

  return (
    <>
      <Header bookTitle={bookSummary.book.title} />
      {loading && <Spinner />}
      {!loading && error && (
        <main aria-label="Contenuto del libro" className="book-container">
          <p className="text-center">Si è verificato un errore</p>
        </main>
      )}
      {!loading && !error && (
        <Outlet
          context={{
            summary: bookSummary.chapters,
            automaticBookmark,
            manualBookmark,
            createChapter,
            deleteChapter,
            updateManualBookmark,
            navigateToManualBookmark,
          }}
        />
      )}
    </>
  );
}

export default withAuthetication(Book);
