import React from "react";
import PropTypes from "prop-types";
import { Transforms } from "slate";
import { ReactEditor, useReadOnly, useSlateStatic } from "slate-react";
import { useInView } from "react-intersection-observer";
import { MdFormatSize } from "react-icons/md";
import classNames from "classnames";

import { useToolbar } from "../../Toolbar";
import useModifiers from "../../useModifiersContext";
import { ModifierButton } from "./HeadingModifiers";
import { stringifyNode } from "../../utils";
import useVisibleHeadingContext from "./useVisibleHeadingContext";
import {
  ButtonConverterToParagraph,
  ButtonConverterToBulletedList,
  ButtonConverterToOrderedList,
  ButtonWrapHighlightBox,
  ButtonConverterToQuote,
  ButtonConverterToAlphabeticList,
  ButtonConverterToExample,
} from "~/src/Editor/BlockConverter";
import Icon from "../../../components/Icon";
import ModalInput from "~/src/layout/Modal/ModalInput";
import logoMyLIM from "~/src/assets/myLIM-icon.png";
import { parseAndValidateInterger } from "../../../utils/validation";

/**
 * Componente che renderizza un header
 * @param {*} param vedi PropTypes
 * @returns Un blocco header
 */
export function Heading({ attributes, children, element }) {
  useToolbar();
  const isReadOnly = useReadOnly();
  const { addHeading, removeHeading, resetHeading, furthestVisibleHeading } =
    useVisibleHeadingContext();
  const editor = useSlateStatic();
  const { ref, inView, entry } = useInView({ threshold: 1 });

  const [myLimModalOpen, setMyLimModalOpen] = React.useState(false);
  const [newMyLIMSecId, setNewMyLIMSecId] = React.useState(
    element?.mylim_id || "",
  );
  const [myLimModalErr, setMyLimModalErr] = React.useState("");

  React.useEffect(() => {
    if (!isReadOnly) {
      if (!furthestVisibleHeading.disabled) resetHeading();
      return;
    }

    const path = ReactEditor.findPath(editor, element);
    if (path.length > 1) return;

    if (entry?.target.id && inView)
      addHeading({ id: entry.target.id, rect: entry.intersectionRect });

    if (entry?.target.id && !inView)
      removeHeading({ id: entry.target.id, rect: entry.intersectionRect });
  }, [
    inView,
    entry,
    element.children,
    isReadOnly,
    editor,
    element,
    addHeading,
    removeHeading,
    furthestVisibleHeading?.disabled,
    resetHeading,
  ]);

  const onClick = (weight) => () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { ...element, weight }, { at: path });
  };

  const toggleUpperCase = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      { ...element, uppercase: !element.uppercase },
      { at: path },
    );
  };

  const modifierBtns = [
    <ModifierButton
      isActive={element.weight === 1}
      ariaLabel="trasforma in h1"
      key="weight-1"
      onClick={onClick(1)}
    >
      1
    </ModifierButton>,
    <ModifierButton
      isActive={element.weight === 2}
      ariaLabel="trasforma in h2"
      key="weight-2"
      onClick={onClick(2)}
    >
      2
    </ModifierButton>,
    <ModifierButton
      isActive={element.weight === 3}
      ariaLabel="trasforma in h3"
      key="weight-3"
      onClick={onClick(3)}
    >
      3
    </ModifierButton>,
    <ModifierButton
      isActive={element.weight === 4}
      ariaLabel="trasforma in h4"
      key="weight-4"
      onClick={onClick(4)}
    >
      4
    </ModifierButton>,
    <ModifierButton
      isTogglable
      isActive={element.uppercase}
      ariaLabel="trasforma in uppercase"
      key="uppercase"
      onClick={toggleUpperCase}
    >
      <Icon label="maiuscolo" icon={MdFormatSize} size="30px" />
    </ModifierButton>,
    <ModifierButton
      ariaLabel="cambia sezione myLIM associata"
      key="mylim_sec_id"
      onClick={() => {
        setMyLimModalOpen(true);
      }}
    >
      <img alt="MyLIM logo" src={logoMyLIM} className="h-full w-full !my-0" />
    </ModifierButton>,
  ];

  const converterBtns = [
    <ButtonConverterToParagraph
      key="changeToParagraph"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToBulletedList
      key="changeToList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToOrderedList
      key="changeToOrderedList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToAlphabeticList
      key="changeToAlphabeticalList"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToQuote
      key="changeToQuote"
      editor={editor}
      element={element}
    />,
    <ButtonConverterToExample
      key="changeToExample"
      editor={editor}
      element={element}
    />,
    <ButtonWrapHighlightBox
      key="wrapHighlightBox"
      editor={editor}
      element={element}
    />,
  ];

  useModifiers({ modifierBtns, converterBtns, element });

  const id = stringifyNode(element, { separator: "-" });

  const editMyLIMSectionIdModal = myLimModalOpen ? (
    <ModalInput
      isOpen
      text="Sezione MyLIM associata a questo titolo"
      inputName="ID sezione MyLIM"
      inputId={`input_mylim_${id}`}
      value={newMyLIMSecId}
      placeholder="Inserisci l'ID della sezione myLIM"
      onChange={(evt) => {
        setNewMyLIMSecId(evt.target.value);
      }}
      primaryText="Annulla"
      onPrimaryClick={() => setMyLimModalOpen(false)}
      dangerText="Salva"
      onDangerClick={() => {
        const newId = parseAndValidateInterger(newMyLIMSecId);
        if (!newId) {
          setMyLimModalErr("Id ezione MyLIM invalido!");
          return;
        }
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(
          editor,
          { ...element, mylim_id: newId },
          { at: path },
        );
        setMyLimModalOpen(false);
      }}
      warningText={myLimModalErr}
    />
  ) : null;

  const HeadingTag = [1, 2, 3, 4].includes(element.weight)
    ? `h${element.weight + 1}`
    : "h5";

  return (
    <div ref={ref} id={id} className="heading">
      <HeadingTag
        className={classNames({ uppercase: element.uppercase })}
        {...attributes}
      >
        {children}
      </HeadingTag>
      {editMyLIMSectionIdModal}
    </div>
  );
}

Heading.propTypes = {
  /**
   * Passati e gestiti da Slate.js
   */
  element: PropTypes.shape({
    weight: PropTypes.number.isRequired,
    mylim_id: PropTypes.number,
    children: PropTypes.array,
    uppercase: PropTypes.bool,
  }).isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  attributes: PropTypes.object.isRequired,
  /**
   * Passati e gestiti da Slate.js
   */
  children: PropTypes.any.isRequired,
};

export default Heading;
