import React, { useState, useEffect } from "react";
import { MdSettings } from "react-icons/md";

import {
  Popover,
  PopoverTrigger,
  PopoverClose,
  PopoverContent,
} from "~/src/components/Popover";
import UICustomizableOption from "./UICostomizableOption";
import ColorSample from "./ColorSample";
import Button from "~/src/components/Button";
import Icon from "~/src/components/Icon";

import {
  setFontFamily,
  setFontSize,
  setFontSpace,
  setFontCase,
  setThemeColor,
  setThemeFromConf,
} from "../../../utils/themes";

function UICustomizer() {
  const [isOpen, setIsOpen] = useState(false);
  const [conf, setConf] = useState(null);

  useEffect(() => {
    const themeConf = JSON.parse(localStorage.getItem("themeConf")) || {
      fontFamily: "sans",
      fontSize: "0",
      fontSpace: "normal",
      fontCase: "normal",
      themeColor:
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light",
    };
    setThemeFromConf(themeConf);
    setConf(themeConf);
  }, []);

  useEffect(() => {
    if (conf) {
      localStorage.setItem("themeConf", JSON.stringify(conf));
    }
  }, [conf]);

  const onChangeFontFamily = (val) => {
    setConf((s) => ({ ...s, fontFamily: val }));
    setFontFamily(val);
  };

  const onChangeFontSize = (val) => {
    setConf((s) => ({ ...s, fontSize: val }));
    setFontSize(val);
  };

  const onChangeFontSpace = (val) => {
    setConf((s) => ({ ...s, fontSpace: val }));
    setFontSpace(val);
  };

  const onChangeFontCase = (val) => {
    setConf((s) => ({ ...s, fontCase: val }));
    setFontCase(val);
  };

  const onChangeThemeColor = (val) => {
    setConf((s) => ({ ...s, themeColor: val }));
    setThemeColor(val);
  };

  if (!conf) {
    return null;
  }

  return (
    <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <PopoverTrigger asChild>
        <Button type="ghost" square>
          <Icon
            icon={MdSettings}
            label={`${isOpen ? "Chiudi" : "Apri"} impostazioni tema`}
            className="w-full h-full p-2"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <>
          <UICustomizableOption
            description="Tipo carattere"
            name="font-family"
            options={[
              {
                val: "serif",
                content: "Serif",
                title: "Imposta carattere con grazie",
                style: "font-serif",
              },
              {
                val: "sans",
                content: "Sans",
                title: "Imposta carattere senza grazie",
                style: "font-sans",
              },
              {
                val: "readable",
                content: "Alta Leggibilità",
                title: "Imposta carattere ad alta leggibilità",
                style: "text-xs font-al",
              },
            ]}
            activeOption={conf.fontFamily}
            onChange={onChangeFontFamily}
          />
          <UICustomizableOption
            description="Dimensione carattere"
            name="font-size"
            options={[
              {
                val: "0",
                content: "a",
                title: "Imposta dimensione carattere normale",
              },
              {
                val: "1",
                content: "a",
                title: "Imposta dimensione carattere grande",
                style: "text-xl",
              },
              {
                val: "2",
                content: "a",
                title: "Imposta dimensione carattere extra",
                style: "text-2xl",
              },
            ]}
            activeOption={conf.fontSize}
            onChange={onChangeFontSize}
          />
          <UICustomizableOption
            description="Dimensione interlinea"
            name="font-space"
            options={[
              {
                val: "normal",
                content: "1x",
                title: "Imposta interlinea normale",
              },
              {
                val: "large",
                content: "1.5x",
                title: "Imposta interlinea largo",
              },
              {
                val: "double",
                content: "2x",
                title: "Imposta interlinea doppio",
              },
            ]}
            activeOption={conf.fontSpace}
            onChange={onChangeFontSpace}
          />
          <UICustomizableOption
            description="Minuscolo / Maiuscolo"
            name="font-case"
            options={[
              {
                val: "normal",
                content: "a",
                title: "Disabilita carattere tutto maiuscolo",
              },
              {
                val: "uppercase",
                content: "A",
                title: "Abilita caratter tutto maiuscolo",
              },
            ]}
            activeOption={conf.fontCase}
            onChange={onChangeFontCase}
          />
          <UICustomizableOption
            description="Tema colori"
            name="theme"
            options={[
              {
                val: "light",
                content: <ColorSample name="Chiaro" color="#FFFFFF" />,
                title: "Imposta tema chiaro",
                style: "btn-lg",
              },
              {
                val: "dark",
                content: <ColorSample name="Scuro" color="#000000" />,
                title: "Imposta tema scuro",
                style: "btn-lg",
              },
              {
                val: "retro",
                content: <ColorSample name="Seppia" color="#E4D7B4" />,
                title: "Imposta tema seppia",
                style: "btn-lg",
              },
            ]}
            activeOption={conf.themeColor}
            onChange={onChangeThemeColor}
          />
          <PopoverClose asChild>
            <button className="sr-only">Chiudi impostazioni tema</button>
          </PopoverClose>
        </>
      </PopoverContent>
    </Popover>
  );
}

export default UICustomizer;
