import React, { useRef } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

function FadeAnimation({ in: inProp, children }) {
  const animationRef = useRef(null);

  return (
    <CSSTransition
      in={inProp}
      timeout={300}
      unmountOnExit
      classNames="fade"
      nodeRef={animationRef}
    >
      <div ref={animationRef}>{children}</div>
    </CSSTransition>
  );
}

FadeAnimation.propTypes = {
  in: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export { FadeAnimation };
