import Axios from "axios";

/**
 * Funzioni per interagire con l'API di autenticazione
 */

const MylimLoginService = Axios.create({
  timeout: 4000,
});

/**
 * Endpoint autenticazione Loescher
 * @param {string} username - l'username
 * @param {string} password - la password
 * @typedef {{token: string, token_loescher: string}} Output
 * @returns {Promise<Output|undefined>} i token CS e Loescher o eccezione Axios
 */
export async function login(username, password) {
  const res = await MylimLoginService.post(
    "https://www.cloudschooling.it/loescher/api/v2/api-token-auth/",
    {
      username,
      password: password,
      app: "libroliquido",
    },
  );
  return res?.data;
}

/**
 * Endpoint autenticazione Loescher tramite token SSO
 * @param {string} token
 * @returns {Promise<string|undefined>} il token JWT o eccezione Axios
 */
export async function loginWithSsoToken(token) {
  const res = await MylimLoginService.post(
    "https://www.cloudschooling.it/loescher/api/v2/sso-login/",
    {
      sso_token: token,
      app: "libroliquido",
    },
  );
  return res?.data?.token;
}
