import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * Costruisce il nome della chiave del bookmark automatico
 * @param {*} isbn
 */
export const getAutomaticBookmarkName = (isbn) => `${isbn}_ab`;

/**
 * Costruisce il nome della chiave del bookmark manuale
 * @param {*} isbn
 */
export const getManualBookmarkName = (isbn) => `${isbn}_mb`;

/**
 * Legge il bookmark data la chiave. Ritorna null se non esiste.
 * @param {string} name
 */
export const loadBookmark = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (error) {
    localStorage.removeItem(name);
    return null;
  }
};

/**
 * Salva il bookmark
 * @param {string} isbn
 * @param {string} chapterId
 * @param {Object} extraData
 */
export const saveBookmark = (name, chapterId, extraData = {}) => {
  try {
    const scrollTop = window.scrollY;
    const docHeight = document.body.scrollHeight;
    const winHeight = window.innerHeight;
    const scrollPercent =
      docHeight > winHeight ? scrollTop / (docHeight - winHeight) : 1;

    const data = {
      chapterId,
      scrollY: window.scrollY,
      percentage: Math.round(scrollPercent * 100),
      ...extraData,
    };

    localStorage.setItem(name, JSON.stringify(data));
    return data;
  } catch (_) {
    // Non esegue nulla
  }
};

/**
 * Alla chiusura della pagina del browser salva il progresso di lettura.
 * @param {string} isbn
 * @param {string} chapterId
 */
export function useAutomaticBookmark(isbn, chapterId) {
  React.useEffect(() => {
    const listener = () => {
      if (isbn && chapterId) {
        saveBookmark(getAutomaticBookmarkName(isbn), chapterId);
      }
    };

    window.addEventListener("beforeunload", listener);

    return () => window.removeEventListener("beforeunload", listener);
  }, [isbn, chapterId]);
}

/**
 * Hook che ritorna i dati relativi al segnalibro manuale, la funzione per settarlo e la funzione
 * per navigare verso il segnalibro.
 * @param {string} isbn
 * @param {string} chapterId
 */
export function useManualBookmark(isbn, chapterId) {
  const [manualBookmark, setManualBookmark] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    setManualBookmark(loadBookmark(getManualBookmarkName(isbn)));
  }, [isbn]);

  const updateManualBookmark = (chapterTitle) => {
    setManualBookmark(
      saveBookmark(getManualBookmarkName(isbn), chapterId, { chapterTitle }),
    );
  };

  const navigateToManualBookmark = () => {
    if (manualBookmark) {
      navigate(manualBookmark.chapterId, {
        state: { scrollY: manualBookmark.scrollY },
      });
    }
  };

  return {
    manualBookmark,
    updateManualBookmark,
    navigateToManualBookmark,
  };
}
